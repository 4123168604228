import React from "react"
import Card from "./News"
import { Container, Row, Col } from "react-bootstrap"

export default ({ isFirstResponders = false, label = null, items }) => {
  return (
    <Container className={"px-0 news-cards pt-5"}>
      <Row>
        {items &&
          items.map((item, i) => {
            if (label) item.frontmatter.label = label

            return (
              <Col
                md={6}
                lg={4}
                key={"resource-col-" + i}
                className={"pb-4 d-flex"}
                data-sal="slide-up"
                data-sal-duration="800"
                data-sal-delay="1200"
                data-sal-easing="ease"
              >
                <Card
                  isFirstResponders={isFirstResponders}
                  key={"resource-card-" + i}
                  {...item}
                />
              </Col>
            )
          })}
      </Row>
    </Container>
  )
}
