import React, { useState } from "react"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import Subheading from "./../components/Subheading"
import Pagination from "./../components/Pagination"
import NewsCards from "./../components/Cards/NewsList"

export default ({
  pageContext: {
    pageInfo: { title, description },
    newsDescendingOrder,
    newsPerPage,
    newsPagesCount,
    currentPage,
    isFirstResponders,
  },
  location: { state },
}) => {
  const [sortBy, setSortBy] = useState(state ? state.sort : "newest")

  const getNewsItems = (sortBy) => {
    return (sortBy === "newest") ? newsDescendingOrder : [...newsDescendingOrder].reverse();
  }

  const newsOffset = newsPerPage * (currentPage - 1)
  const [currentPageNewsItems, setCurrentPageNewsItems] = useState(
    getNewsItems(sortBy)
      ? [...getNewsItems(sortBy).slice(newsOffset, newsOffset + newsPerPage)]
      : []
  )

  const seoTitle = isFirstResponders ? `First Responders News - Page ${currentPage} of ${newsPagesCount}` : `In The News - Page ${currentPage} of ${newsPagesCount}`;

  return (
    <Layout
      pageInfo={{
        pageName: seoTitle,
      }}
    >
      <SEO title={seoTitle} />
      <Subheading title={title}>{description}</Subheading>
      <Pagination
        sortMode={state ? state.sort : "newest"}
        onClickSort={sortBy => {
          setSortBy(sortBy);
          if (newsOffset + newsPerPage >= getNewsItems(sortBy).length) {
            setCurrentPageNewsItems([...getNewsItems(sortBy).slice(newsOffset)])
          } else {
            setCurrentPageNewsItems([...getNewsItems(sortBy).slice(newsOffset, newsOffset + newsPerPage)])
          }
        }}
        urlPrefix={isFirstResponders ? "/first-responders/news" : "/in-the-news"}
        currentPage={currentPage}
        numPages={newsPagesCount}
      />
      <NewsCards isFirstResponders={isFirstResponders} items={currentPageNewsItems} />
      <Pagination
        urlPrefix={isFirstResponders ? "/first-responders/news" : "/in-the-news"}
        sorting={false}
        currentPage={currentPage}
        numPages={newsPagesCount}
      />
    </Layout>
  )
}
